@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap");

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  font-family: "Mukta", sans-serif;
  /* margin: 10px; */
  padding: 10px;
  color: black;

  position: fixed; /* Add this line */
  top: 0; /* Add this line */
  left: 0; /* Add this line */
  right: 0; /* Add this line */
  background-color: white;
}

.navbar-div {
  color: black;
  border: 1px solid black;
}

.menu {
  display: flex;
  list-style-type: none;
}

li {
  margin-left: 30px;
  margin-right: 10px;
  font-size: 18px;
  cursor: pointer;
}


.flip {
  display: inline-block;
  perspective: 1000px;
  font-size: 20px;
  font-weight: bold;
}


/* Styles for the hamburger menu */
.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger-line {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
}

@media (max-width: 700px) {
  .navbar {
    justify-content: space-between;
    padding: 20px;
  }
  .menu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .menu li {
    margin: 10px 0;
  }

  .hamburger {
    display: block;
  }
  .new-menu {
    display: none;
  }
  
  .show {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
}