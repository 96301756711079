.reviews-section-div {
    background-color: #1E2E40;
    font-family: "Mukta", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
}

.reviews-heading-div {
    text-align: center;
}

.r-first-heading {
    color: white;
    line-height: 1.2;
    font-weight: 300;
    font-size: 55px;
    padding: 0;
    letter-spacing: -2px;
    margin: 0;

    text-decoration: underline;
    text-decoration-color: #545260;
    text-underline-offset: 5px;
    letter-spacing: 1px;
    text-indent: 10px;  
}

.r-second-heading {
    color: white;
    line-height: 1.2;
    font-weight: 900;
    font-size: 55px;
    padding: 0;
    letter-spacing: -2px;
    margin: 0;
}


@media screen and (max-width: 662px) {
    .reviews-section-div {
        background-color: #1E2E40;
        font-family: "Mukta", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 0;
    }
}