.pricing {
    font-family: "Mukta", sans-serif;
    margin-top: 100px;
    margin-bottom: 100px; /* remove this afterwards */
}

.pricing-heading {
    text-align: center;
}

.p-first-heading {
    color: rgb(168, 168, 168);
    line-height: 1.2;
    font-weight: 300;
    font-size: 55px;
    padding: 0;
    letter-spacing: -2px;
    margin: 0;
    text-decoration: underline;
    text-decoration-color: #f0f0f0;
    text-underline-offset: 5px;
    letter-spacing: 1px;
    text-indent: 10px;  
}

.p-second-heading {
    line-height: 1.2;
    font-weight: 900;
    font-size: 55px;
    padding: 0;
    letter-spacing: -2px;
    margin: 0;
}

.pricing-cards {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricing-card, .pro-card {
    /* border: 1px solid black; */
    width: 400px;
    height: 600px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add this line */
}

.card-title {
    text-align: center;
    padding: 20px 0;
}

.card-title p {
    color: rgb(5, 109, 161);
    font-weight: 900;
    font-size: 30px;
}

.card-title span {
    color: rgb(82, 82, 82);
}

.grey-line {
    width: 100%;
    height: 1px;
    background-color: rgb(224, 224, 224);
}

.price {
    text-align: center;
    font-size: 50px;
    font-weight: 900;
}
.price-p {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    font-weight: bold;
}

.button-div {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
}

.pricing-cards button {
    background-color: white;
    color: #091C2F;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 40px;
    width: 300px;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
}

.pricing-cards button:hover {
    background-color: #091C2F;
    color: white;
    cursor: pointer;
}

.features {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    margin: 30px 30px;
    line-height: 2.9;
}

.pro-card {
    height: 680px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add this line */
}

.pro-card button {
    background-color: #056DA1;
    color: white;
}

.pro-card button:hover {
    color: white;
}

.plan-desc {
    width: 20px;
    margin: 0 50px;
    border: 1px solid red;
}


@media screen and (max-width: 1226px) {
    .pricing-cards {
        flex-direction: column;
    }
    .pricing-card, .pro-card {
        margin-top: 20px;
        width: 70%;
    }
}
@media screen and (max-width: 510px) {
    .pricing-card, .pro-card {
        margin-left: 70px;
        margin-top: 10px;
        width: 100%;
    }
    .pricing-heading {
        margin-left: 120px;
    }
}
@media screen and (max-width: 478px) {
    .pricing-card, .pro-card {
        margin-left: 160px;
        margin-top: 10px;
        width: 100%;
    }
}