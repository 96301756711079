.third-section-div {
    margin-top: 100px;
    justify-content: center;
}

.t-heading {
    font-weight: 400;
    font-size: 55px;
    padding: 0;
    letter-spacing: -2px;
    line-height: 1.0;
    margin: 0;
    color: rgb(189, 189, 189);
    
    text-decoration: underline;
    text-decoration-color: #f0f0f0;
    text-underline-offset: 5px;
    letter-spacing: 1px;
    text-indent: 10px;  
}

.cards {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    margin-bottom: 30px;
}

@media screen and (max-width: 1207px) {
    .cards {
        flex-direction: column;
        align-items: center;
        margin-left: 30px;
    }
}

@media screen and (max-width: 450px) {
    .cards {
        margin-left: 150px;
    }
}