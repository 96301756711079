.fourth-section-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.key-points {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.key-points p {
    margin-left: 10px;
    color: rgb(84, 84, 84);
}

.all-key {
    margin-top: 20px;
}

@media screen and (max-width: 945px) {
    .fourth-section-div {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .fourth-right-div {
        margin-top: 40px;
        text-align: center;
    }
    .key-points p {
        margin-left: 10px;
        color: rgb(84, 84, 84);
        font-size: 20px;
    }
    .key-points {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }
    .all-key {
        text-align: center;
    }
}


@media screen and (max-width: 519px) {
    .fourth-right-div {
        margin-left: 70px;
        text-align: center;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .all-key {
        margin-left: 70px;
        text-align: center;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 519px) {
    .fourth-right-div {
        margin-left: 0px;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}